<template>
  <div id="playWnd" class="playWnd" :style="`width:${width}px;height:${height}px`"></div>
</template>

<script>
import { WebControl } from '../assets/js/jsWebControl-1.0.0.min.js'
import JSEncrypt from 'jsencrypt'

export default {
  props: {
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 300
    },
    // 监控点编号
    pointCode: {
      type: String
    },
    // 这里是来控制你一开始初始化之后的分屏的
    layout: {
      type: String,
      default: '1x1'
    },
    info: {
      type: Object,
      default() {
        return {
          appKey: '28022728', // 综合安防管理平台提供的appkey，必填
          appSecret: 'wACQUoEN8q08bu1DzHL1', // 综合安防管理平台提供的secret，必填
          host: '61.180.39.9:1443' // 综合安防管理平台IP地址，必填
        }
      }
    }
  },
  watch: {
    // 页面加载时创建播放实例初始化
    // 监听摄像头参数的变化
    info() {
      this.init()
    },
    pointCode() {
      this.startPreview()
    }
  },
  data() {
    return {
      swfHeight: '',
      swfWidth: '',
      // 初始化参数
      initparam: {
        appKey: '',
        secret: '',
        apiIp: '',
        apiPort: 8099
      },
      pubKey: '',
      oWebControl: null,
      WebControl: null,
      initCount: 0,
      checkWnd: 1
    }
  },
  beforeMount() {
    this.WebControl = WebControl
    // console.log(this.WebControl)
  },
  mounted() {
    // 监听resize事件，使插件窗口尺寸跟随DIV窗口变化
    this.initPlugin()
    window.addEventListener('resize', () => {
      if (this.oWebControl != null) {
        this.oWebControl.JS_Resize(this.swfWidth, this.swfHeight)
        this.setWndCover()
      }
    })
    // 监听滚动条scroll事件，使插件窗口跟随浏览器滚动而移动
    window.addEventListener('scroll', () => {
      if (this.oWebControl != null) {
        this.oWebControl.JS_Resize(this.swfWidth, this.swfHeight)
        this.setWndCover()
      }
    })
  },
  beforeDestroy() {
    this.closeWindow() // 关闭插件
  },
  methods: {
    initPlugin() {
      this.oWebControl = new WebControl({
        szPluginContainer: 'playWnd', // 指定容器id
        iServicePortStart: 15900, // 指定起止端口号，建议使用该值
        iServicePortEnd: 15909,
        szClassId: '23BF3B0A-2C56-4D97-9C03-0CB103AA8F11', // 用于IE10使用ActiveX的clsid
        cbConnectSuccess: () => { // 创建WebControl实例成功
          this.oWebControl.JS_StartService('window', { // WebControl实例创建成功后需要启动服务
            dllPath: './VideoPluginConnect.dll' // 值"./VideoPluginConnect.dll"写死
          }).then(() => { // 启动插件服务成功
            this.oWebControl.JS_SetWindowControlCallback({ // 设置消息回调
              cbIntegrationCallBack: this.cbIntegrationCallBack
            })

            this.oWebControl.JS_CreateWnd('playWnd', this.width, this.height).then(() => { // JS_CreateWnd创建视频播放窗口，宽高可设定
              this.oWebControl.JS_RequestInterface({
                funcName: 'getRSAPubKey',
                argument: JSON.stringify({
                  keyLength: 1024
                })
              }).then((oData) => {
                // console.log(oData)
                if (oData.responseMsg.data) {
                  this.pubKey = oData.responseMsg.data
                  this.init()// 创建播放实例成功后初始化
                }
              })
            })
          }, () => { // 启动插件服务失败
            this.$message.error('插件未启动')
          })
        },
        cbConnectError: () => { // 创建WebControl实例失败
          this.$message.error('插件未启动')
          this.$emit('plugin-error')
          // this.oWebControl = null
          // this.$message.error(this.initCount === 0 ? '插件未启动，正在尝试启动，请稍候...' : '插件未启动，正在尝试重新启动')
          // this.WebControl.JS_WakeUp('VideoWebPlugin://') // 程序未启动时执行error函数，采用wakeup来启动程序
          // this.initCount++
          // if (this.initCount < 2) {
          //   const timer = setTimeout(() => {
          //     this.initPlugin()
          //     clearTimeout(timer)
          //   }, 3000)
          // }
          // this.initCount++
          // if (this.initCount < 3) {
          //   setTimeout(() => {
          //     this.initPlugin()
          //   }, 3000)
          // } else {
          //   this.$message.error('插件启动失败，请检查插件是否安装！')
          //   this.$confirm('是否下载插件？')
          //     .then(async _ => {
          //       window.location.href = 'https://xsw-xf.oss-cn-hangzhou.aliyuncs.com/xiaofang/VideoWebPlugin.exe'
          //     })
          //     .catch(_ => { })
          // }
        },
        cbConnectClose: (bNormalClose) => {
          // 异常断开：bNormalClose = false
          // JS_Disconnect正常断开：bNormalClose = true
          // console.log('cbConnectClose')
          this.oWebControl = null
        }
      })
    },
    // 初始化
    init() {
      // console.log(this.pointCode)
      // 请自行修改以下变量值
      const appkey = this.info.appKey // 综合安防管理平台提供的appkey，必填
      const secret = this.setEncrypt(this.info.appSecret) // 综合安防管理平台提供的secret，必填
      const ip = this.info.host.replace(':1443', '') // 综合安防管理平台IP地址，必填
      const playMode = 0 // 初始播放模式：0-预览，1-回放
      const port = 1443 // 综合安防管理平台端口，若启用HTTPS协议，默认443
      const snapDir = 'D:\\SnapDir' // 抓图存储路径
      const videoDir = 'D:\\VideoDir' // 紧急录像或录像剪辑存储路径
      const layout = this.layout // playMode指定模式的布局
      const enableHTTPS = 1 // 是否启用HTTPS协议与综合安防管理平台交互，这里总是填1
      const encryptedFields = 'secret' // 加密字段，默认加密领域为secret
      const showToolbar = 1 // 是否显示工具栏，0-不显示，非0-显示
      const showSmart = 1 // 是否显示智能信息（如配置移动侦测后画面上的线框），0-不显示，非0-显示
      const buttonIDs = '0,16,256,257,258,259,260,512,513,514,515,516,517,768,769' // 自定义工具条按钮

      const param = {
        funcName: 'init',
        argument: JSON.stringify({
          appkey: appkey, // API网关提供的appkey
          secret: secret, // API网关提供的secret
          ip: ip, // API网关IP地址
          playMode: playMode, // 播放模式（决定显示预览还是回放界面）
          port: port, // 端口
          snapDir: snapDir, // 抓图存储路径
          videoDir: videoDir, // 紧急录像或录像剪辑存储路径
          layout: layout, // 布局
          enableHTTPS: enableHTTPS, // 是否启用HTTPS协议
          encryptedFields: encryptedFields, // 加密字段
          showToolbar: showToolbar, // 是否显示工具栏
          showSmart: showSmart, // 是否显示智能信息
          buttonIDs: buttonIDs // 自定义工具条按钮
        })
      }

      this.oWebControl.JS_RequestInterface(param).then((oData) => {
        // console.log(oData)
        this.oWebControl.JS_Resize(this.width, this.height)// 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
        this.startPreview()
      })
    },
    // getPubKey (callback) {
    //   this.oWebControl.JS_RequestInterface({
    //     funcName: 'getRSAPubKey',
    //     argument: JSON.stringify({
    //       keyLength: 1024
    //     })
    //   }).then((oData) => {
    //     console.log(oData)
    //     if (oData.responseMsg.data) {
    //       this.pubKey = oData.responseMsg.data
    //       callback()
    //     }
    //   })
    // },
    setEncrypt(str) { // 注册方法
      const encryptStr = new JSEncrypt()
      encryptStr.setPublicKey(this.pubKey) // 设置 加密公钥
      const data = encryptStr.encrypt(str.toString())// 进行加密
      return data
    },
    // 设置窗口控制回调
    setCallbacks() {
      this.oWebControl.JS_SetWindowControlCallback({
        cbIntegrationCallBack: this.cbIntegrationCallBack
      })
    },
    // 推送消息
    cbIntegrationCallBack(oData) {
      const data = oData.responseMsg
      switch (data.type) {
        case 1:
          this.checkWnd = data.msg.wndId
          break
        case 6:
          this.startPreview()
          break
      }
      /* showCBInfo(JSON.stringify(oData.responseMsg)); */
    },
    setWndCover() {
      const iWidth = document.body.clientWidth
      const iHeight = document.body.clientWidth
      const oDivRect = document.querySelector('#playWnd').get(0).getBoundingClientRect()
      let iCoverLeft = (oDivRect.left < 0) ? Math.abs(oDivRect.left) : 0
      let iCoverTop = (oDivRect.top < 0) ? Math.abs(oDivRect.top) : 0
      let iCoverRight = (oDivRect.right - iWidth > 0) ? Math.round(oDivRect.right - iWidth) : 0
      let iCoverBottom = (oDivRect.bottom - iHeight > 0) ? Math.round(oDivRect.bottom - iHeight) : 0

      iCoverLeft = (iCoverLeft > this.width) ? this.width : iCoverLeft
      iCoverTop = (iCoverTop > this.height) ? this.height : iCoverTop
      iCoverRight = (iCoverRight > this.width) ? this.width : iCoverRight
      iCoverBottom = (iCoverBottom > this.height) ? this.height : iCoverBottom

      this.oWebControl.JS_RepairPartWindow(0, 0, 1001, this.height)// 多1个像素点防止还原后边界缺失一个像素条
      if (iCoverLeft !== 0) {
        this.oWebControl.JS_CuttingPartWindow(0, 0, iCoverLeft, this.height)
      }
      if (iCoverTop !== 0) {
        this.oWebControl.JS_CuttingPartWindow(0, 0, 1001, iCoverTop)// 多剪掉一个像素条，防止出现剪掉一部分窗口后出现一个像素条
      }
      if (iCoverRight !== 0) {
        this.oWebControl.JS_CuttingPartWindow(this.width - iCoverRight, 0, iCoverRight, this.height)
      }
      if (iCoverBottom !== 0) {
        this.oWebControl.JS_CuttingPartWindow(0, this.height - iCoverBottom, this.width, iCoverBottom)
      }
    },
    // 视频“预览”功能
    startPreview() {
      if (!this.pointCode) {
        this.$message.warning('未获取该摄像头的监控点编号')
        this.$emit("error")
        return
      }
      let params = {}
      let data = {}
      const multiPlayParam = {
        argument: {
          list: []
        },
        funcName: 'startMultiPreviewByCameraIndexCode'
      }
      let wndNum = 0
      const pointCodeIndex = this.pointCode.split(',')
      this.oWebControl.JS_RequestInterface({
        funcName: 'getLayout'
      }).then((oData) => {
        data = JSON.stringify(oData.responseMsg.data)
        data = data.replace(/\\n/g, '')
        data = data.replace(/\\/g, '')
        /* eslint-disable */
        data = data.replace(/\"{/g, '{')
        data = data.replace(/}\"/g, '}')
        /* eslint-enable */
        wndNum = JSON.parse(data).wndNum
        const videoLength = wndNum > pointCodeIndex.length ? pointCodeIndex.length : wndNum
        for (let i = 0; i < videoLength; i++) {
          params = {
            cameraIndexCode: pointCodeIndex[i], // 监控点编号
            streamMode: 0, // 主子码流标识
            transMode: 1, // 传输协议
            gpuMode: 0, // 是否开启GPU硬解
            wndId: i + 1 // 可指定播放窗口
          }
          multiPlayParam.argument.list.push(params)
        }
        multiPlayParam.argument = JSON.stringify(multiPlayParam.argument)
        this.oWebControl.JS_RequestInterface(multiPlayParam).then(oData => {
          // console.log(oData)
        })
      })
    },
    changePreview(cameraIndexCode) {
      const params = {
        cameraIndexCode: cameraIndexCode, // 监控点编号
        streamMode: 0, // 主子码流标识
        transMode: 1, // 传输协议
        gpuMode: 0, // 是否开启GPU硬解
        wndId: this.checkWnd // 可指定播放窗口
      }
      this.oWebControl.JS_RequestInterface({
        funcName: 'startPreview',
        argument: JSON.stringify(params)
      }).then(oData => {
        // console.log(oData)
      })
    },
    // 停止全部预览功能
    stopAllPreview() {
      this.oWebControl.JS_RequestInterface({
        funcName: 'stopAllPreview'
      })
    },
    // 关闭视频窗口
    closeWindow() {
      if (this.oWebControl != null) {
        this.oWebControl.JS_HideWnd() // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
        this.oWebControl.JS_Disconnect().then(
          () => {
            // 断开与插件服务连接成功
          },
          () => {
            // 断开与插件服务连接失败
          }
        )
      }
    },
    uninit() {
      this.oWebControl.JS_RequestInterface({
        funcName: 'uninit'
      }, null, 2).then((oData) => {
        this.oWebControl.JS_Resize(this.width, this.height)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.playWnd {
  ::v-deep img {
    display: none;
  }
}
</style>
